import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Info from '../components/UnlockPage/Info'

export default ({ pageContext: { data } }) => {
  return (
    <Layout 
      data={data} 
      signUpUrl={data.info?.buttonUrl} 
      signUpText={data.info?.buttonText} 
      footerDisable={true}
      navStatic={true}
    >
      <SEO {...data.seo} />
      <Info info={data.info} />
    </Layout>
  )
}
